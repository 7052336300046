<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <h1 class="login-header">
          {{ $t("ServicePage.Welcome back to ITEL") }}
        </h1>
        <!-- form -->
        <validation-observer ref="phoneNumberForm">
          <!-- email -->
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|msisdn"
          >
            <label class="d-block">Nhập số iTel để nhận OTP</label>
            <b-input-group label-for="email" label="Email">
              <b-form-input
                v-model="so_tb"
                :state="errors.length > 0 ? false : null"
                placeholder="0878123456"
                autofocus
                type="number"
                @keypress="onInputPhoneNumber"
              />
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  class="rounded ml-1 bg-red"
                  @click="sendOTP"
                >
                  Gửi OTP
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="fs-error-txt" v-if="errors.length > 0"
              >Vui lòng nhập số thuê bao iTel 087</small
            >
          </validation-provider>
        </validation-observer>

        <validation-observer ref="otpForm">
          <!-- password -->
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required|otpNum3"
          >
            <label class="d-block mt-2">Nhập mã OTP được gửi về số iTel</label>
            <b-form-input
              ref="inputOTPRef"
              :disabled="isDisableOTPText"
              v-model="otp"
              type="password"
              maxlength="3"
              :state="errors.length > 0 ? false : null"
              @keydown="isNumber"
              placeholder="123"
            />
            <small class="fs-error-txt" v-if="errors.length > 0"
              >Nhập mã OTP gồm 3 chữ số</small
            >
          </validation-provider>

          <!-- submit button -->
          <b-button
            class="mt-3 login-btn"
            variant="primary"
            block
            @click="login"
          >
            Đăng nhập
          </b-button>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, number, date } from "@validations";
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";

// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

extend("msisdn", (value) => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== "8487" && first3 !== "087") {
    return false;
  } else if (
    (first4 === "8487" && value.length !== 11) ||
    (first3 === "087" && value.length !== 10)
  ) {
    return false;
  }
  return true;
});
extend("num1", (value) => {
  if (value.length != 6) return false;
  return !/\D/.test(value);
});
extend("otpNum3", (value) => {
  if (value.length != 3) return false;
  return /\d/.test(value);
});

export default {
  components: {
    Swiper,
    SwiperSlide,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormRadio,
    BCol,
    BRow,
    BFormInput,
    BInputGroupAppend,
    BCard,
    BCardText,
    BFormCheckbox,
    BInputGroup,
    BButton,
    FeatherIcon,
  },
  data() {
    return {
      currentItem: {},
      so_tb: "",
      otp: "",
      required,
      isDisableOTPText: true,
    };
  },
  watch: {},
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    this.setAnimation();
    // insider_object
    window.insider_object = window.insider_object || {};
    window.insider_object.page = {
      type: "dang-nhap",
    };
  },
  methods: {
    Img(pic) {
      return images[pic];
    },
    async login() {
      window.insider_object.user = {
        uuid: uuidv4(),
        name: "",
        surname: "",
        username: "",
        email: "",
        phone_number: this.so_tb,
        language: "vi",
        returning: true,
      };
      if (this.so_tb == "" && this.otp == "") {
        this.$toast.warning("Vui lòng nhập số thuê bao iTel và mã OTP", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.so_tb == "") {
        this.$toast.warning("Vui lòng nhập số iTel nhận OTP", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (this.otp == "") {
        this.$toast.warning("Vui lòng nhập OTP", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      // const isValidOTP = await this.$refs["otpForm"].validate();
      // if(!isValidOTP) {
      //   this.$toast.error('Sai mã OTP, vui lòng thử lại', {icon:true, closeButton: "button", type: "error", toastClassName: "otp-error__login"});
      //   return;
      // }
      this.loading(true);
      useJwt
        .post("otp-login", { username: this.so_tb, password: this.otp })
        .then((response) => {
          console.log(response.data.result);
          localStorage.token = response.data.result.token;
          localStorage.tokenTime = new Date().getTime();
          localStorage.data = response.data.result.msisdn;
          if (localStorage.redirectUrl) {
            this.$router.push({ path: localStorage.redirectUrl });
          } else {
            this.$router.push({ path: "/" });
          }
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          // code 403 => wrong otp
          if (err.response.data.code == 403) {
            this.otp = "";
            return this.$toast.error("Sai mã OTP, vui lòng thử lại", {
              icon: true,
              closeButton: "button",
              type: "error",
            });
          }

          this.$toast.error(err.response.data.message, {
            icon: true,
            closeButton: "button",
            type: "error",
          });
        });
    },

    async sendOTP() {
      if (this.otp.length > 0) {
        this.otp = "";
      }
      const isValidPhoneNumber = await this.$refs["phoneNumberForm"].validate();
      if (!isValidPhoneNumber) {
        this.$toast.error("Vui lòng nhập đúng số thuê bao iTel 087", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      this.loading(true);
      useJwt
        .get("otp/" + this.so_tb)
        .then((response) => {
          this.$toast.info("Vui lòng kiểm tra tin nhắn từ số 8968", {
            icon: true,
            closeButton: "button",
          });
          this.isDisableOTPText = false;
          setTimeout(() => {
            if (this.$refs["inputOTPRef"]) {
              this.$refs["inputOTPRef"].focus();
            }
          }, 50);
          this.loading(false);
        })
        .catch((err) => {
          this.isDisableOTPText = true;
          this.loading(false);
          this.$toast.error(err.response.data.message, {
            icon: true,
            closeButton: "button",
          });
        });
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector(".fs-header").clientHeight;
      [].slice
        .call(
          document.querySelectorAll(
            ".fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr"
          )
        )
        .forEach((elm) => {
          const style = window.getComputedStyle(elm, null);
          const elmH = style.getPropertyValue("height");
          const inputT = windowH - elm.getBoundingClientRect().top;
          if (inputT > 0 && inputT < windowH + parseInt(elmH, 10) - headH / 2) {
            elm.classList.add("fs-ani");
          } else {
            elm.classList.remove("fs-ani");
          }
        });
    },
    onInputPhoneNumber(evt) {
      this.so_tb = this.so_tb.replace(/[^0-9\.]+/g, "");
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    isNumber(evt) {
      const keyPressed = evt.key;

      if (
        this.otp.length >= 3 &&
        keyPressed !== "Delete" &&
        keyPressed !== "Backspace" &&
        keyPressed !== "ArrowLeft" &&
        keyPressed !== "ArrowRight" &&
        keyPressed !== "ArrowUp" &&
        keyPressed !== "ArrowDown"
      ) {
        evt.preventDefault();
        return;
      }
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
      ];

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
  },
};
</script>

<style>
.otp-error__login {
  background-color: #ff5252 !important;
  color: #fff !important;
}
</style>
<style scoped>
.login-btn {
  font-size: 20px;
}
.bg-red {
  border-color: #ed1f24 !important;
  background-color: #ed1f24 !important;
  font-size: 20px;
}
@media screen and (max-width: 800px) {
  .login-header {
    font-size: 5vw;
    margin-bottom: 4.8vw;
  }
  .login-btn {
    font-size: 3.8vw;
  }
  .bg-red {
    font-size: 3.8vw;
  }
}
</style>